const config = {
    gatsby: {
        pathPrefix: '/',
        siteUrl: 'https://developer.vitalcareadmin.com',
        gaTrackingId: null
    },
    header: {
        logo: '',
        title: 'VitalTech Developers Guide',
        githubUrl: '',
        helpUrl: '',
        tweetText: '',
        links: [{ text: 'VitalTech Affiliates LLC', link: 'https://vitaltech.com' }]
    },
    sidebar: {
        forcedNavOrder: ['/introduction', '/authenticate', '/postVitals', '/retrieveVitals'],
        links: [{ text: '', link: '' }]
    },
    siteMetadata: {
        title: 'VitalTech Developers Guide',
        description: 'Documentation to help developers understand how to use the VitalTech APIs',
        ogImage: null,
        docsLocation: '',
        favicon: '/icons/favicon.ico'
    }
};

module.exports = config;
