module.exports = [{
      plugin: require('/tmp/build_dcb1773c_/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/tmp/build_dcb1773c_/src/templates/docs.js"},
    },{
      plugin: require('/tmp/build_dcb1773c_/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1035,"sizeByPixelDensity":true}},{"resolve":"gatsby-remark-copy-linked-files"}],"extensions":[".mdx",".md"]},
    },{
      plugin: require('/tmp/build_dcb1773c_/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/build_dcb1773c_/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":null,"head":true,"anonymize":false},
    },{
      plugin: require('/tmp/build_dcb1773c_/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
