import styled from 'react-emotion';

const Code = styled('code')`
    background: #f9f7fb;
    border: 1px solid #ede7f3;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 0.9375em;
`;

export default Code;
