import React from 'react';
import './components/styles.css';
import { RedocStandalone } from 'redoc';

const RedocEmbed = ({ link }) => {
    return (
        <div className="redoc-responsive">
            <RedocStandalone specUrl={link} />
        </div>
    );
};

export default RedocEmbed;
